import React from 'react';
import { Link } from 'react-router-dom';
import dungeonIcon from './icon/dungeon.png'; // アイコン画像のインポート
import rafaelIcon from './icon/rafael.png'; // アイコン画像のインポート
import backgroundImage from './icon/main.png'; // 背景画像のインポート
import './styles.css'; // CSSファイルのインポート
import HamburgerMenu from './HamburgerMenu';

function MainPage() {
    return (
      <div
        className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
        style={{
          backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`, // インポートした背景画像を使用
        }}
      >
      <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
        <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
          猫LIFE
        </h1>
        <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
      </header>
  
        <main className="container mx-auto px-4 py-8">
          <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
            <h2 className="text-xl md:text-2xl mb-6 text-[#FFD700]">
              イベント攻略
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Link
                to="/dansion"
                className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center"
              >
                <img src={dungeonIcon} alt="Dungeon Icon" className="icon-image" />
                <span>ダンジョン鉱山</span>
              </Link>
              <Link
                to="/rafael"
                className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center"
              >
                <img src={rafaelIcon} alt="Rafael Icon" className="icon-image" />
                <span>ラファエル</span>
              </Link>
            </div>
          </div>
        </main>
      </div>
    );
  }
  
  export default MainPage;
