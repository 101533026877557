import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート

const RafaelPage = () => {
    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    ラファエル
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <ul>
                        <li><Link to="https://www.evernote.com/shard/s352/sh/2d00a715-188e-5ede-9796-7b9826043937/1eIr0jf513lofXa7SJGZI0wLlrntLbv0AFZsGwOfiz0xegBwN0De-wLRbg" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">まとめ</Link></li>
                        <li><Link to="/rafaelVideo?video=1" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">3列目(無加工)</Link></li>
                        <li><Link to="/rafaelVideo?video=2" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">4列目①(無加工)</Link></li>
                        <li><Link to="/rafaelVideo?video=3" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">4列目②(無加工)</Link></li>
                        <li><Link to="/rafaelVideo?video=4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">4列目③(無加工)</Link></li>
                        <li><Link to="/rafaelVideo?video=5" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">5列目①(無加工)</Link></li>
                        <li><Link to="/rafaelVideo?video=6" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">5列目②(無加工)</Link></li>
                        <li><Link to="/rafaelVideo?video=7" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">5列目③(無加工)</Link></li>
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default RafaelPage;