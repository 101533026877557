import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート

const DansionPage = () => {
    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    ダンジョン鉱山
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <h2 className="text-xl md:text-2xl mb-6 text-[#FFD700]">
                        階層を選択してください
                    </h2>
                    <ul>
                        <li><Link to="/image?image=5&type=png" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">5階層</Link></li>
                        <li><Link to="/image?image=6&type=png" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">6階層</Link></li>
                        <li><Link to="/image?image=7&type=png" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">7階層</Link></li>
                        <li><Link to="/image?image=8&type=png" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">8階層</Link></li>
                        <li><Link to="/image?image=9&type=png" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">9階層</Link></li>
                        <li><Link to="/image?image=10&type=png" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">10階層</Link></li>
                        <li><Link to="/image?image=11&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">11階層</Link></li>
                        <li><Link to="/video?video=27&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">27階層</Link></li>
                        <li><Link to="/video?video=28&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">28階層</Link></li>
                        <li><Link to="/video?video=29&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">29階層</Link></li>
                        <li><Link to="/video?video=30&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">30階層</Link></li>
                        <li><Link to="/video?video=31&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">31階層</Link></li>
                        <li><Link to="/video?video=32&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">32階層</Link></li>
                        <li><Link to="/video?video=33&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">33階層</Link></li>
                        <li><Link to="/video?video=34&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">34階層</Link></li>
                        <li><Link to="/video?video=35&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">35階層</Link></li>
                        <li><Link to="/video?video=36&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">36階層</Link></li>
                        <li><Link to="/video?video=37&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">37階層</Link></li>
                        <li><Link to="/video?video=38&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">38階層</Link></li>
                        <li><Link to="/video?video=39&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">39階層</Link></li>
                        <li><Link to="/video?video=40&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">40階層</Link></li>
                        <li><Link to="/video?video=41&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">41階層</Link></li>
                        <li><Link to="/video?video=42&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">42階層</Link></li>
                        <li><Link to="/video?video=43&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">43階層</Link></li>
                        <li><Link to="/video?video=44&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">44階層</Link></li>
                        <li><Link to="/video?video=45&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">45階層</Link></li>
                        <li><Link to="/video?video=46&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">46階層</Link></li>
                        <li><Link to="/video?video=47&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">47階層</Link></li>
                        <li><Link to="/video?video=48&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">48階層</Link></li>
                        <li><Link to="/video?video=49&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">49階層</Link></li>
                        <li><Link to="/video?video=50&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">50階層</Link></li>
                        <li><Link to="/video?video=51&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">51階層</Link></li>
                        <li><Link to="/video?video=52&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">52階層</Link></li>
                        <li><Link to="/video?video=53&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">53階層</Link></li>
                        <li><Link to="/video?video=54&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">54階層</Link></li>
                        <li><Link to="/video?video=55&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">55階層</Link></li>
                        <li><Link to="/video?video=56&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">56階層</Link></li>
                        <li><Link to="/video?video=57&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">57階層</Link></li>
                        <li><Link to="/video?video=58&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">58階層</Link></li>
                        <li><Link to="/video?video=59&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">59階層</Link></li>
                        <li><Link to="/video?video=60&type=mov" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">60階層</Link></li>
                        <li><Link to="/video?video=61&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">61階層</Link></li>
                        <li><Link to="/video?video=62&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">62階層</Link></li>
                        <li><Link to="/video?video=63&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">63階層</Link></li>
                        <li><Link to="/video?video=64&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">64階層</Link></li>
                        <li><Link to="/video?video=65&type=mp4" className="bg-[#333] hover:bg-[#444] p-4 rounded-lg transition-colors duration-300 flex items-center">65階層</Link></li>
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default DansionPage;