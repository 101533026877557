import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './HamburgerMenu.css'; // 必要に応じてスタイルを設定

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // メニューの開閉を切り替える関数
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // 前のページに戻る関数
  const goBack = () => {
    navigate(-1); // 1ページ前に戻る
  };

  return (
    <div className="hamburger-menu">
      <button className="hamburger-icon" onClick={toggleMenu}>
        &#9776; {/* ハンバーガーアイコン */}
      </button>
      {isOpen && (
        <nav className="menu-items">
          <ul>
            <li><Link to="/" onClick={toggleMenu}>トップ画面に戻る</Link></li>
            <li><button onClick={() => { goBack(); toggleMenu(); }}>前ページに戻る</button></li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default HamburgerMenu;
