import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import DansionPage from './DansionPage';
import ImagePage from './ImagePage';
import VideoPage from './VideoPage';
import RafaelPage from './RafaelPage';
import RafaelVideoPage from './RafaelVideoPage';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/dansion" element={<DansionPage />} />
                <Route path="/rafael" element={<RafaelPage />} />
                <Route path="/rafaelVideo" element={<RafaelVideoPage />} />
                <Route path="/image" element={<ImagePage />} />
                <Route path="/video" element={<VideoPage />} />
            </Routes>
        </Router>
    );
};

export default App;